@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap');

:root {
  --bg-color: #161519;
  --bg-light-color: #1e1c22;
  --weight-color: #fff;

  --main-color: 0, 122, 255;
  --main-color-opacity-100: rgba(var(--main-color), 1);
  --main-color-opacity-50: rgba(var(--main-color), 0.5);
  --main-color-opacity-70: rgba(var(--main-color), 0.7);
  --main-color-opacity-35: rgba(var(--main-color), 0.35);
  --main-color-opacity-20: rgba(var(--main-color), 0.2);
  --main-color-opacity-1: rgba(var(--main-color), 0.1);
  --main-color-opacity-05: rgba(var(--main-color), 0.05);
}

* {
  border: none;
  box-sizing: border-box;
  font-family: 'IBM Plex Sans Arabic', sans-serif;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
  scroll-behavior: smooth;
  cursor: url('../src/assest/cursor-icon.png'), auto !important;
}

::-webkit-scrollbar {
  width: 12px !important;
}

::-webkit-scrollbar-thumb {
  background: var(--main-color-opacity-100) !important;
  border-radius: 12px;
  transition: all 0.3s ease;
  box-shadow: none !important;
}

::-webkit-scrollbar-track {
  background: var(--bg-light-color);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  font-family: 'IBM Plex Sans Arabic', sans-serif;
  background-color: var(--bg-color);
  overflow-x: clip;
  color: var(--weight-color);
  scroll-behavior: smooth;
  cursor: url('../src/assest/cursor-icon.png'), auto !important;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::selection {
  -webkit-text-fill-color: initial !important;
  background-clip: initial !important;
  -webkit-background-clip: initial !important;
  background-color: var(--main-color-opacity-100);
  background-image: none !important;
  color: #f5f5f5 !important;
}

a {
  color: var(--weight-color);
}

section {
  position: relative;
}

/* loader */
.loader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5555;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  background: rgb(255 255 255 / 20%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loader.none {
  display: none;
}

.lang_loader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5555;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  background: rgb(255 255 255 / 20%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.lang_loader.none {
  display: none;
}

.pl {
  width: 6em;
  height: 6em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.pl__ring {
  animation: ringA 2s linear infinite;
}

.pl__ring--a {
  stroke: var(--main-color-opacity-100);
}

.pl__ring--b {
  animation-name: ringB;
  stroke: var(--main-color-opacity-20);
}

.pl__ring--c {
  animation-name: ringC;
  stroke: var(--main-color-opacity-35);
}

.pl__ring--d {
  animation-name: ringD;
  stroke: var(--main-color-opacity-50);
}

/* Animations */
@keyframes ringA {

  from,
  4% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }

  12% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -335;
  }

  32% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -595;
  }

  40%,
  54% {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -660;
  }

  62% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -665;
  }

  82% {
    stroke-dasharray: 60 600;
    stroke-width: 30;
    stroke-dashoffset: -925;
  }

  90%,
  to {
    stroke-dasharray: 0 660;
    stroke-width: 20;
    stroke-dashoffset: -990;
  }
}

@keyframes ringB {

  from,
  12% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -110;
  }

  20% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -115;
  }

  40% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -195;
  }

  48%,
  62% {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  70% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  90% {
    stroke-dasharray: 20 200;
    stroke-width: 30;
    stroke-dashoffset: -305;
  }

  98%,
  to {
    stroke-dasharray: 0 220;
    stroke-width: 20;
    stroke-dashoffset: -330;
  }
}

@keyframes ringC {
  from {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  8% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  28% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  36%,
  58% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  66% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  86% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  94%,
  to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}

@keyframes ringD {

  from,
  8% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: 0;
  }

  16% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -5;
  }

  36% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -175;
  }

  44%,
  50% {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -220;
  }

  58% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -225;
  }

  78% {
    stroke-dasharray: 40 400;
    stroke-width: 30;
    stroke-dashoffset: -395;
  }

  86%,
  to {
    stroke-dasharray: 0 440;
    stroke-width: 20;
    stroke-dashoffset: -440;
  }
}

/* loader */

.container {
  margin: 0 auto;
  max-width: 1200px;
  width: 90%;
  /* margin-top: 8rem; */
}

header {
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  /* background: #00000061; */
  top: 0;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

header.active {
  background: #00000061;
}

header::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 80%;
  background: linear-gradient(90deg, rgba(2, 0, 36, 0), var(--main-color-opacity-100) 49%, rgba(0, 212, 255, 0));
  height: 1px;
  transform: translateX(-50%);
}

header .container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  position: relative;
}

header .links {
  align-items: center;
  display: flex;
  gap: 20px;
}

.language {
  background: transparent;
  border: 2px solid gray;
  font-size: 1.4rem;
  color: unset;
  border-radius: .5rem;
  padding: .2rem;
  min-width: 6rem;
}

/* .lang_loader .language {
  min-width: 6rem;
} */

header .logo img {
  width: 165px;
}

header .links li {
  position: relative;
}

header .links a {
  font-size: 1.7rem;
  transition: all .3s ease-in-out;
}

header .links a.size-about {
  font-size: 1.8rem;
}

header .links a:hover {
  color: var(--main-color-opacity-100);
}

header .links a.active {
  color: var(--main-color-opacity-100);
}

header .menu {
  display: none;
}


.button {
  height: 60px;
  width: 150px;
  position: relative;
  /* background-color: var(--main-color-opacity-100); */
  background: linear-gradient(45deg, var(--main-color-opacity-100), #313035, transparent);
  cursor: url('../src/assest/cursor-icon.png'), auto !important;
  /* border: 2px solid #252525; */
  overflow: hidden;
  border-radius: 1rem;
  color: #333;
  transition: all 0.5s ease-in-out;
}

header .btn-txt {
  font-size: 1.2rem;
}

.btn-txt {
  z-index: 1;
  font-weight: 800;
  font-size: 1.2rem;
}

.type1::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1rem;
  width: 0%;
  height: .5rem;
  background: var(--main-color-opacity-100);
}

.type1.active::before {
  width: 100%;
}

.projects.services.all .type1:hover::after {
  transform: scale(0) translateX(2px) !important;
}

.projects.services.all .type1 {
  overflow: initial;
}

.type1::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.5s ease-in-out;
  background-color: #333;
  border-radius: 30px;
  visibility: hidden;
  height: 10px;
  width: 10px;
  z-index: -1;
}

.button:hover {
  box-shadow: 1px 1px 200px var(--main-color-opacity-50);
  color: #fff;
  border: none;
}

.type1:hover::after {
  visibility: visible;
  transform: scale(100) translateX(2px);
}

/* start home */
.home {
  height: 100vh;
  background-image: linear-gradient(#1e1c22, #1e1c22);
  /* background-image: linear-gradient(180deg, #605FE5 0%, #4B4AF2 100%); */
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.home .blur1 {
  position: absolute;
  width: 25rem;
  height: 31rem;
  border-radius: 50%;
  left: -19rem;
  background: var(--main-color-opacity-35);
  opacity: .1;
  box-shadow: 55px -7px 52px 0px var(--main-color-opacity-35);
}

.home .blur2 {
  position: absolute;
  width: 25rem;
  height: 31rem;
  border-radius: 50%;
  right: -19rem;
  background: var(--main-color-opacity-35);
  opacity: .1;
  box-shadow: -55px -7px 52px 0px var(--main-color-opacity-35);
  bottom: -5rem;
}

.home .container {
  margin-top: 17rem;
  /* margin-top: 11rem; */
}

.home .social {
  position: absolute;
  right: 60px;
  z-index: 99;
  top: 50%;
  transform: translateY(-50%);
}

.home .social ul {
  gap: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home .social li {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.home .social li a {
  font-size: 20px;
  color: rgba(255, 255, 255, .7);
  transition: .4s;
  width: 2rem;
}

.home .social li a:hover {
  color: var(--main-color-opacity-100);
}

.home .content {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 7rem;
  /* margin-bottom: 3rem; */
}

.home .content .text-area {
  flex: 0 0 auto;
  width: 47%;
  margin: auto;
}

.home .content .image-area {
  flex: 0 0 auto;
  width: 47%;
}

.home .content .text-area .text {
  max-width: 740px;
  width: 100%;
}

.home .content .text-area .text h1 {
  font-weight: 700;
  font-size: 50px;
  line-height: 1.3;
  text-transform: capitalize;
  color: var(--white-color);
  margin-bottom: 30px;
}

.home .content .text-area .text h1 span {
  color: var(--main-color-opacity-100);
  position: relative;
  font-size: 50px;
}

/* .home .content .text-area .text h1 span::after {
  content: url("../src/assest/text-bg.png");
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -35%);
  opacity: .2;
  z-index: -1;
} */

.home .content .text-area .text p {
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  color: #e4e4e4;
  margin-bottom: 55px;
}

.home .content .text-area .btns {
  display: flex;
  align-items: center;
  gap: 60px;
}


.btn.two {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: .03em;
  text-transform: uppercase;
  color: var(--white-color);
  display: flex;
  align-items: center;
  transition: .5s;
}

.btn.two svg {
  fill: var(--main-color-opacity-100);
}

.btn.two .btn-content {
  margin-left: -40px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: .5s;
  z-index: 1;
  font-size: 1.2rem;
}

.btn.two i {
  transition: .3s;
  transform: rotate(45deg);
}

.btn.two:hover i {
  transform: rotate(90deg);
}

.home .content .image-area {
  flex: 0 0 auto;
  width: 50%;
}

.home .content .image-area .image {
  width: 100%;
  position: relative;
  animation: landing_zoom 6s infinite;
  text-align: center;
}


.home .content .image-area .image img {
  /* max-width: 945px;
  width: 72%; */
  max-width: 266px;
  width: 72%;
}

.home .reviews {
  align-items: center;
  display: flex;
  text-align: center;
  padding: 1rem;

}

.home .reviews .icon {
  margin: auto;
  font-size: 2rem;
  fill: rgba(var(--main-color), 1);
}

.home .reviews .icon.dollar {
  font-size: 2rem;
  fill: rgba(var(--main-color), 1);
}

.home .reviews .icon i {
  color: var(--main-color-opacity-100);
  font-size: 2rem;
}

.home .reviews .counter {
  padding: 0 30px;
  width: 100%;
}

.home .reviews .counter ul {
  margin: 0;
  padding: 0;
  list-style: none;
  -moz-columns: 4;
  columns: 3;
  gap: 0;
}

.home .reviews .counter ul li {
  display: flex;
  justify-content: center;
  position: relative;
}

.home .reviews .counter .single-counter::after {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(255, 255, 255, .1);
}

.home .reviews .counter .single-counter .number {
  display: flex;
  align-items: baseline;
}

.home .reviews .counter .single-counter p {
  font-weight: 400;
  font-size: 30px;
  letter-spacing: .02em;
  text-transform: capitalize;
  color: #b5b5b5;
  padding-top: 3px;
}

.home .reviews .counter .single-counter .number .counter {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 38px;
  text-transform: capitalize;
  color: var(--white-color);
}

.home .reviews .counter .single-counter .number .counter span {
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
  color: var(--white-color);
  display: inline-block;
  margin-left: 5px;
}

/* end home */

/* about */
.title {
  position: relative;
  padding: 2rem 0;
}

.title.two {
  position: relative;
  padding: 0;
}

.title span {
  text-align: center;
  font-weight: 700;
  font-size: 100px;
  letter-spacing: .01em;
  text-transform: uppercase;
  color: var(--main-color-opacity-05);
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  line-height: 1;
}

.title p {
  text-align: center;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 60px;
  letter-spacing: .01em;
  color: var(--white-color);
}

.title.two p {
  text-align: center;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 30px;
  color: var(--main-color-opacity-70);
}

.about {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 3rem;
}

.about .priceSpan:nth-of-type(1) {
  background: #2196F3;
}


.about .about-content {
  text-align: center;
  margin-top: 2rem;
}

.about .image {
  width: 200px;
  height: 200px;
  /* border-radius: 50%; */
  border: 2px solid var(--main-color-opacity-100);
  box-shadow: 0px 0px 15px var(--main-color-opacity-35);
}

.about .text {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #d5d2d2;
  margin: 40px 0;
  width: 100%;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}

.about .swiper_container {
  user-select: none;
}

.about .swiper_container img {
  width: 100%;
}

.about .swiper-slide .card {
  align-items: center;
  background-color: #f1f1f1;
  border: 1px solid #cecece;
  box-shadow: 5px 5px 0 #7f7e7f59;
  display: flex;
  height: 100px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 150px;
  z-index: 2;
  margin: auto;
  user-select: none;
}

.about .swiper-slide .card::before {
  background: var(--main-color);
  box-shadow: 9px 7px 11px 0 #000;
  content: "";
  height: 5rem;
  left: -5rem;
  opacity: .4;
  position: absolute;
  top: -2rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 5rem;
}

.about .swiper-slide img {
  width: 80%;
  filter: sepia(1);
}

.about .swiper-slide .card:hover img {
  filter: initial;
}



/* about */

/* certificates */
.certificates {
  padding-top: 5rem;
  padding-bottom: 3rem;
  position: relative;
  background-color: var(--bg-light-color);
}

.contact-menu {
  position: fixed;
  bottom: 0px;
  left: 10px;
  z-index: 1000;
}

.contact-menu .whatsapp_link {
  background-image: url('../src/assest/whatsapp.png');
  background-size: 90px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  width: 100px;
  height: 100px;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.main {
  display: none;
  flex-direction: column;
  gap: 0.5em;
  margin-bottom: -5rem;
}

.main.active {
  display: flex;
}

.certificates .exit,
.projects .exit,
.contact .exit {
  position: absolute;
  right: 5%;
  top: 3rem;
}

.certificates .menu,
.projects .menu,
.contact .menu {
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
}

.certificates .menu span,
.projects .menu span,
.contact .menu span {
  width: 3rem;
  height: 4px;
  background: var(--weight-color);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.certificates .menu.active .span1,
.projects .menu.active .span1,
.contact .menu.active .span1 {
  transform: rotate(45deg);
}

.certificates .menu.active .span2,
.projects .menu.active .span2,
.contact .menu.active .span2 {
  display: none;
}

.certificates .menu.active .span3,
.projects .menu.active .span3,
.contact .menu.active .span3 {
  transform: rotate(120deg);
}

.certificates .cards {
  display: grid;
  gap: 4rem !important;
  column-gap: 6rem;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  width: 87%;
  margin: auto;
  margin-top: 2rem;
}

.certificates .cards .card {
  align-items: center;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  background: hsla(0, 0%, 100%, .1);
  border: 2px solid #615f5f;
  border-radius: 10px;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, .1);
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: .3s ease-in-out;
  width: 230px;
  height: 200px;
  z-index: 2;
  margin: auto;
  cursor: url('../src/assest/cursor-icon.png'), auto !important;
  position: relative;
  flex-direction: column;
  padding: 0 .5rem;
  text-align: center;
}

.certificates .cards .card::before {
  position: absolute;
  content: '';
  width: 5rem;
  height: 5rem;
  top: -36px;
  left: 0;
  border-radius: 50%;
  background: var(--main-color-opacity-100);
  z-index: -1;
}

.certificates .cards .card::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: var(--main-color-opacity-1);
  z-index: 30;
}

.certificates .cards .card:hover:after {
  width: 0;
}

.certificates .cards .card:hover img {
  scale: 1.1;
}

.certificates .cards .card img {
  transition: .3s ease;
  border-radius: .5rem;
  max-height: 120px;
  transition: .3s ease;
  width: 180px;
}

.mempries {
  background-color: var(--bg-light-color);
}

.certificates .btn.two {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: .03em;
  text-transform: uppercase;
  color: var(--white-color);
  display: flex;
  align-items: center;
  transition: .5s;
  justify-content: end;
  padding: 3rem 5px 0;
  width: 275px;
  margin-left: auto;
}

.certificates .btn.two .btn-content {
  margin-left: -40px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: .5s;
  z-index: 1;
}

/* certificates */

/* projects */
.projects {
  padding-top: 5rem;
  padding-bottom: 3rem;
  position: relative;
  /* background: var(--bg-light-color); */
}

.service {
  background: var(--bg-light-color);
}

.projects .btn.two {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: .03em;
  text-transform: uppercase;
  color: var(--white-color);
  display: flex;
  align-items: center;
  transition: .5s;
  justify-content: end;
  padding: 0rem 5px 1rem;
  width: 275px;
  margin-left: auto;
}

.projects .btn.two .btn-content {
  margin-left: -40px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: .5s;
  z-index: 1;
}

.projects .cards {
  display: grid;
  gap: 1rem;
  column-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  width: 97%;
  margin: 2rem auto;
}

.projects .cards .card {
  position: relative;
  max-width: 412px;
  width: 100%;
  height: 300px;
  margin: auto;
  margin-right: 30px;
  overflow: hidden;
  padding: 1rem;
  box-shadow: 0 0 2px 0px rgba(var(--main-color), 0.35);
  transition: all .4s ease-in-out;
  cursor: url('../src/assest/cursor-icon.png'), auto !important;
  z-index: 2;
  margin: auto;
}

.projects.blogs .cards .card {
  height: auto;
  max-height: 340px !important;
}

.projects .cards .card::before {
  position: absolute;
  content: '';
  width: 155px;
  height: 155px;
  background-color: var(--main-color-opacity-100);
  opacity: .2;
  border-radius: 50%;
  bottom: -2rem;
  right: -2rem;
  transition: all .3s ease-in-out;
  z-index: 1;
}

.projects .cards .card:hover i {
  transform: rotate(90deg);
}

.projects .cards .card:hover::before {
  width: 110%;
  height: 115%;
  opacity: .1;
  border-radius: 0%;
}

.projects .cards .card::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#161519, #161519);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  transition: .5s;
}

.projects .cards .card h2 {
  font-weight: 700;
  font-size: 23px;
  letter-spacing: .03em;
  color: var(--main-color-opacity-70);
  margin-bottom: 0;
  /* margin-top: 2rem; */
  margin-left: 1rem;
}

.projects .cards .card h3 {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: .02em;
  text-transform: capitalize;
  color: var(--weight-color);
  margin-top: 1rem;
  margin-left: 1rem;
}

.projects .cards .card p {
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: .03em;
  color: #b5b5b5;
  margin-bottom: 30px;
  margin-left: 1rem;
  height: 6rem;
}

.projects .cards .card a {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.projects .cards .card i {
  transition: all .4s ease-in-out;
}

.projects .img-swiper {
  width: 7rem;
  border-radius: .5rem;
}

.services .cards .card {
  height: 250px;
  text-align: center;
}

.services .cards .card p {
  font-weight: 900;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: .03em;
  color: #b5b5b5ad;
  margin-bottom: 30px;
  margin-left: 1rem;
  height: 0rem;
}


.projects form .input-box .input-field {
  position: relative;
  width: 100%;
  margin: .8rem 0;
  display: flex;
  align-items: center;
  margin: auto;
}

.projects form .input-box .input-field input {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  padding-left: 3rem;
  font-size: 1rem;
  border-radius: .6rem;
  border: 2px solid rgba(var(--main-color), 0.35);
  color: #737373;
  background: transparent;
  position: relative;
  z-index: 2;
}

.projects form .icon {
  position: absolute;
  left: 1rem;
  font-size: 2rem;
  color: var(--main-color-opacity-100);
}

.services .btns {
  text-align: center;
  padding-top: 1rem;
}

/* projects */

/* blogs */
section.blogs {
  background-color: var(--bg-light-color) !important;
}

/* blogs */

/* memories */
.memories {
  /* background-color: var(--bg-light-color); */
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.projects {
  background-color: var(--bg-color) !important;
}

.projects .certificates {
  background-color: var(--bg-color) !important;
}

.memories .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  user-select: none;
}

.memories .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 400px;
  height: 400px;
  border-radius: 1rem;
}

.memories .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.memories .swiper-pagination-bullet-active {
  background-color: var(--main-color-opacity-100) !important;
  border-radius: 1rem;
}

.swiper-3d .swiper-slide-shadow-left {
  border-radius: 1rem;
  background-image: linear-gradient(45deg, var(--main-color-opacity-100), transparent) !important;
}

.swiper-3d .swiper-slide-shadow-right {
  border-radius: 1rem;
  background-image: linear-gradient(45deg, var(--main-color-opacity-100), transparent) !important;
}

/* memories */
/* contact */
.contact {
  background-color: var(--bg-light-color);
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.contact-content {
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 16rem; */
}

.contact-content .contact-social {
  transform: translateY(-10%);
  justify-content: center;
  gap: 1rem;
  display: grid;
  gap: 1rem;
  column-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  width: 80%;
  margin: 2rem auto;

}

.contact-content .contact-social i {
  position: absolute;
  color: var(--weight-color);
}

.contact-content .contact-social .btn-sosial .hover {
  transition: all .3s ease-in-out;
  opacity: 0;
  right: 0;
  top: 10rem;
  width: 100%;
  font-size: 10rem;
}

.contact-content .contact-social .btn-sosial:not(.hover):hover .hover {
  position: absolute;
  color: var(--weight-color);
  width: 100%;
  font-size: 7rem;
  z-index: -1;
  opacity: .2;
}

.contact-content .contact-social span {
  /* width: 50px;
  height: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background: var(--main-color-opacity-100); */
  border-radius: .5rem;
}

.contact form {
  max-width: 50rem;
  margin: 0 auto;
  text-align: center;
}

.contact form input:focus {
  color: var(--weight-color) !important;
}

.contact form .input-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact form .icon {
  position: absolute;
  left: 1rem;
  font-size: 1.5rem;
  color: var(--main-color-opacity-100);
}

.contact form .icon.message {
  margin-top: 1.5rem;
}

.contact form .input-box .input-field {
  position: relative;
  width: 49%;
  margin: .8rem 0;
  display: flex;
  align-items: center;
}

.contact form .textarea-field {
  position: relative;
  margin: .8rem 0 2.7rem;
  display: flex;
}

.contact form .textarea-field textarea {
  resize: none;
}


.contact form .input-box .input-field input,
.contact form .textarea-field textarea {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  padding-left: 3rem;
  font-size: 1rem;
  border-radius: .6rem;
  border: 2px solid rgba(var(--main-color), 0.35);
  color: #737373;
  background: transparent;
  position: relative;
  z-index: 2;
}

.contact form .input-box .input-field input::placeholder,
.services form .input-box .input-field input::placeholder,
.contact form .textarea-field textarea::placeholder {
  color: var(--text-color);
}

.contact .btn {
  height: 60px;
  width: 150px;
  position: relative;
  background-color: var(--main-color-opacity-100);
  cursor: url('../src/assest/cursor-icon.png'), auto !important;
  /* border: 2px solid #252525; */
  overflow: hidden;
  border-radius: 1rem;
  color: #333;
  transition: all 0.5s ease-in-out;
}

.contact .btn-sosial {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  position: relative;
  /* overflow: hidden; */
  border-radius: 7px;
  cursor: url('../src/assest/cursor-icon.png'), auto !important;
  transition: all .3s;
}

.contact .btn-sosial i {
  font-size: 3rem;
}

.contact .svgContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  backdrop-filter: blur(4px);
  letter-spacing: 0.8px;
  border-radius: 10px;
  transition: all .3s;
  border: 1px solid rgba(156, 156, 156, 0.466);
}

.contact .BG-social {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(var(--main-color-opacity-100), #313035, transparent);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  z-index: -1;
  border-radius: 9px;
  pointer-events: none;
  transition: all .3s;
}


.contact .btn-sosial:hover .BG-social {
  transform: rotate(35deg);
  transform-origin: bottom;
}

.contact .btn-sosial:hover .svgContainer {
  background-color: rgba(156, 156, 156, 0.466);
}

.pay {
  text-align: center;
  padding-top: 2rem;
}

.pay .boxs {
  display: flex;
  justify-content: center;
}

.pay h3 {
  font-size: 1.5rem;
  color: var(--main-color-opacity-100);
  padding: 1rem;
}

.contact .map {
  margin: auto;
  max-width: 32rem;
  width: 100%;
  margin-top: 3rem;
  padding: 1rem 0 0;
  text-align: center;
  background: var(--main-color-opacity-35);
  z-index: 44;
  position: relative;
}

.contact .map iframe {
  width: 100%;
  height: 20rem;
}

.contact .labelContact {
  color: var(--main-color-opacity-100);
}

/* contact */

/* service */
.service .card-service .img {
  align-items: center;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  background: hsla(0, 0%, 100%, .1);
  border: 2px solid #615f5f;
  border-radius: 10px;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, .1);
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: .3s ease-in-out;
  width: 100%;
  max-width: 40rem;
  z-index: 2;
  margin: auto;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACc0lEQVR4nO3ZTUgUUQDA8ReUYaRgBRZZhyC9CELUISqiQ3UID0HdKggtKJBQqoNWe+jSaglihbKSUBi5WNBhRUvZ6mAdog+QmnXd1fV7F7Vaxdav/cfTtlymsEjd92L/MNd5/Gbem5nHCBEvXry/qjDwfIOAZULX8vobru733fdZep3rhK6ABMNKpsc2VuR37ha6AhIMK+vbyqbPDTQWCF0BCYaVVa4SjvU+rhe69DBo5KZ7Kn4AIkd2l/2jxe9cLVQPyB6cGuNg1wMTYntHdfCSvzlLqA4AmAxPUxh4ZkKkucun8v3NJ4XqgEg1X1pJdt2IQiS5rpPT56gSOgBkLWM9bGq/aV4X3fYP+V0tiUJ1gKx3coRdnXdNiB3e6uGCwNN0oTpAFgpPkdvvMCG2tN+eOD/QdFioDohk+/Ru5v0wF5HiKg2f6Wso1gIgezLqJdVdFoVYaVg50vPopQXncuUBsvbxYbK8VaYptafznv/ioDNNeYAsOD0ur7oJsdVTEcrzNx5QHiALAyVDr2am0FzEWldp+FRf/RXlAZHqggYpbaVRiESjePZjcKk2Sf8CkL0NDbDPV0Om10aGp5KN7vKZxX6ou9Zj+dyyRnnAPL0BNusMkAWAvToDZCEgV2dApEpghc4A2QsgNVaA98BR4DhwGrgAXAauAbeAKsAOOIAm4DXQCniAIWD0+3k6gKylBowAGQs0ZjKQtNSAE0LF+DPAHaFqzA9wLdjtjgHg64ItthgBcoTq8XtArdAhfg1wy0ed0BQQArYJXcIMOCt0imhAndAtfgJ8wOLvoBYJMAHsFDrGLCBf6BqQis6/WePF+8/6Bp1EDzCbyT/8AAAAAElFTkSuQmCC), auto !important;
  position: relative;
  margin-top: 2rem;
  padding: 2rem;
  text-align: center;
}

.service .card-service .img::before {
  position: absolute;
  content: '';
  width: 7rem;
  height: 7rem;
  top: -36px;
  left: -01rem;
  border-radius: 50%;
  background: var(--main-color-opacity-100);
  z-index: -1;
}

.service .img img {
  width: 100%;
  max-width: 35rem;
  border-radius: .5rem;
}

.service .content {
  width: 100%;
  max-width: 55rem;
  text-align: center;
  margin: auto;
  padding-top: 2rem;
}

.service .content h3 {
  font-size: 2rem;
  color: var(--main-color-opacity-100);
}

.service .content p {
  font-size: 1.4rem;
}

.service .box {
  margin-bottom: 2rem;
}

.service .priceP {
  line-height: 2.5;
}

.service .priceSpan {
  background: var(--main-color-opacity-20);
  border-radius: .3rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

/* service */
/* blogs */
.blogs {
  background-color: var(--bg-light-color) !important;
}

/* blogs */
/* projects */
.projects .swiper-slide {
  background-position: center;
  background-size: cover;
  /* width: 400px; */
  /* height: 400px; */
  border-radius: 1rem;
  backdrop-filter: blur(4px) !important;
  -webkit-backdrop-filter: blur(4px) !important;
  background: hsla(0, 0%, 100%, .1) !important;
  border-color: transparent #c70039;
  border-color: transparent var(--main-color) transparent var(--main-color);
  border-radius: 10px !important;
  border-style: solid;
  display: flex !important;
  /* height: 23rem!important; */
  height: 100% !important;
  justify-content: center !important;
  margin: auto !important;
  max-width: 24rem !important;
  overflow: hidden !important;
  padding-top: 1rem !important;
  padding: 1rem 1rem 0;
  position: relative !important;
  transition: .3s ease-in-out !important;
  width: 92% !important;
  z-index: 2 !important;
  flex-direction: column !important;
  text-align: center;
}

/* projects */
/* news */
.news .swiper-slide-active .cardNews {
  position: relative;
  cursor: pointer;
}

.news .swiper-slide-active .cardNews::after {
  position: absolute;
  content: 'اضغط للتصفح';
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  left: 50%;
  top: 50%;
  background: var(--main-color-opacity-35);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: bold;
}


/* news */
/* footer */
.footer {
  padding: 2rem 2rem 1rem;
  /* margin-top: 5rem; */
  position: relative;
  overflow: hidden;
  background: var(--bg-light-color);
}

.footer::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 80%;
  background: linear-gradient(90deg, rgba(2, 0, 36, 0), var(--main-color-opacity-100) 49%, rgba(0, 212, 255, 0));
  height: 1px;
  transform: translateX(-50%);
}

.footer .footer-top {
  text-align: center;
  padding-bottom: 2rem;
}

.footer img {
  width: 100%;
  max-width: 200px;
  text-align: center;
}

.footer .footer-bottom {
  padding: 1rem 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-top: 1px solid #494949;
  border-radius: 0.2rem;
}

.footer .footer-bottom p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  color: #adadad;
}

.footer .footer-bottom p a {
  font-size: 17px;
}

.footer .footer-bottom p a:hover {
  color: var(--main-color-opacity-100);
}

/* footer */

/* media */
@media (max-width:1375px) {
  .home {
    height: auto;
  }

  .home .container {
    margin-top: 8rem;
  }

  .home .content {
    justify-content: center;
    /* margin-bottom: 0; */
  }

  .home .content .text-area {
    width: 100%;
  }

  .home .content .text-area .text {
    text-align: center;
    margin: auto;
  }

  .home .content .text-area .btns {
    justify-content: center;
  }

  .contact form {
    max-width: 45rem;
  }

  .contact-content .contact-social {
    right: 10%;
  }

}

@media (max-width:1200px) {
  .contact {
    min-height: 120vh;
  }

  .contact-content .contact-social {
    right: 47%;
    top: 115%;
    flex-direction: row;
  }

  .contact-content {
    padding-top: 2rem;
  }

  .contact-content .contact-social .btn-sosial .hover {
    right: -1.5rem;
    top: 4.5rem;
    font-size: 8rem;
  }

  .footer {
    /* margin-top: 12rem; */
  }
}

@media (max-width:900px) {
  header .container.active {
    flex-direction: column;
    /* height: 40vh; */
  }

  .home .content .image-area .image img {
    width: 100%;
  }

  header .container ul {
    top: -7rem;
    position: absolute;
  }

  header .container.active ul {
    flex-direction: column;
    position: static;
    line-height: 1;
    padding: 1rem 0;
  }

  header .container .button {
    width: 120px;
    height: 45px;
    top: -5rem;
    position: absolute;
  }

  header .container.active .button {
    position: static;
  }

  header .container .menu {
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
  }

  header .container .menu.active .span1 {
    transform: rotate(45deg);
  }

  header .container .menu.active .span2 {
    display: none;
  }

  header .container .menu.active .span3 {
    transform: rotate(120deg);
  }

  header .container .menu span {
    width: 3rem;
    height: 4px;
    background: var(--weight-color);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  header .container .menu .span2 {
    width: 2rem;
  }

  .certificates .exit,
  .projects .exit,
  .contact .exit {
    top: 3rem;
  }


}

@media (max-width:735px) {
  header .links a {
    font-size: 1.5rem;
  }

  .social {
    display: none;
  }

  .home {
    min-height: 100vh;
  }

  .home .content .text-area {
    /* margin-bottom: 3rem; */
  }

  .home .content .text-area .text h1 {
    font-size: 28px;
  }

  .home .content .text-area .text p {
    font-size: 15px;
  }

  .home .content .text-area .text p {
    font-size: 15px;
  }

  .home .content .text-area .button {
    width: 200px;
    height: 50px;
  }

  .home .content .text-area .button a {
    font-size: .85rem;
  }

  .home .content .text-area .btns .btn.two {
    font-size: 13px;
  }

  .home .reviews .counter .single-counter .number .counter {
    font-size: 20px;
  }

  .home .reviews .icon i {
    font-size: 1rem;
  }

  .home .content .text-area .text p {
    margin-bottom: 30px;
    line-height: 30px;
  }

  .home .reviews {
    padding-top: 2rem;
  }

  .home .reviews {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }

  .home .reviews .counter {
    padding: 0;
  }

  .home .reviews .counter ul {
    columns: 3;
  }

  .home .reviews .counter ul li {
    margin-bottom: 1rem;
  }

  .home .reviews .counter .single-counter p {
    /* width: 9rem; */
  }

  .home .reviews .counter .single-counter p {
    font-size: 14px;
  }

  .image-area {
    /* display: none; */
  }

  .home .content .text-area .text h1 span {
    font-size: 30px;
  }

  .home .content .text-area .btns .btn.two .btn-content {
    gap: 0px;
  }

  .title span {
    font-size: 50px;
  }

  .title p {
    font-size: 38px;
  }

  .about .swiper_container img {
    width: 240%;
  }

  .memories .swiper-slide {
    width: 250px;
    height: 300px;
    background: var(--bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .input-field input,
  .contact form .textarea-field textarea {
    padding: 1rem !important;
    padding-left: 2.5rem !important;
    font-size: .8rem !important;
  }

  .contact form .input-box {
    flex-direction: column;
  }

  .contact form .input-box .input-field {
    width: 100%;
  }

  .contact form .icon {
    left: 1rem !important;
    font-size: 1.5rem !important;
  }

  .contact form .icon.email {
    font-size: 1.2rem !important;
  }

  .contact form .icon.message {
    margin-top: .8rem;
  }

  .contact form .button {
    height: 55px;
    width: 120px;
  }
}

@media (max-width:535px) {
  .contact-content .contact-social {
    right: 43%;
  }

  .type1::after {
    background-color: transparent;
  }
}

@media (max-width:600px) {
  .certificates .cards {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }
}


/* media */